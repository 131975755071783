import React from "react"
import Logo from "../../assets/makinn/makinn_logo.png"
import { Link } from "react-router-dom"

const Error404 = () => {
    return (
        <div className="h-screen w-full flex items-center justify-center bg-black">
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-center text-white">Página no encontrada</h1>
                <img src={Logo} alt="logo makinn" className="md:w-[150px] sm:w-[150px] mx-auto" />
                <Link to="/" className="text-center px-6 py-2 bg-makinn_primary text-white rounded-lg mt-4 uppercase font-bold">
                    Página principal
                </Link>
            </div>
        </div>
    );
};

export default Error404;