import { motion, AnimatePresence } from "framer-motion"
import { QRCodeSVG } from "qrcode.react"
const QrModal = ({showQr,setShowQr,url,fullname}) => {
    return (
        <AnimatePresence>
            {
                showQr &&
                <motion.div 
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    exit={{opacity:0}}
                    transition={{duration:0.3}}
                    className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 bg-black bg-opacity-50"                >
                    <div className="bg-white md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
                        <div className="p-4 flex items-center justify-center w-[300px]">
                            <div className="flex flex-col justify-center items-center w-full"> 
                                <div className="cursor-pointer z-50 mb-8 w-full flex justify-end" onClick={()=>setShowQr(false)}>
                                    <motion.svg 
                                        whileHover={{scale:1.1}}
                                        whileTap={{scale:0.9}}
                                        className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path d="M1 1l16 16m0-16L1 17" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </motion.svg>
                                </div>
                                <QRCodeSVG 
                                    value={url} 
                                    size={256}
                                />   
                                <p className="text-center text-gray-700 text-md mt-6 font-body">Escanea el código QR para ver el perfil de <span className="font-bold">{fullname}</span></p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}
export default QrModal