

const generateVcard = (data) => {
   const { name, lastname, email, phone, puesto, adrr } = data;

   const file = new Blob(
    [
        `BEGIN:VCARD
VERSION:3.0
N:${lastname};${name};;;
FN:${name} ${lastname}
TITLE:${puesto}
EMAIL;type=INTERNET;type=pref:${email}
TEL;type=CELL;type=VOICE;type=pref:${phone}
ADR;type=WORK;type=pref:;;;${adrr ? adrr : ""};;;
END:VCARD
`
      ],
        { type: "text/vcard;charset=utf-8" }
    );            
    
    return file;
};
export default generateVcard;
