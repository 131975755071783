import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateAccount from "../pages/CreateAccount/CreateAccount";
import Home from "../pages/Home/Home";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Error404 from "../pages/Error404/Error404";
import EditProfile from "../pages/EditProfile/EditProfile";
import PolicyAdvice from "../pages/PolicyAdvice/PolicyAdvice";
import BookAppointment from "../pages/BookAppointment/BookAppointment";
import ListDating from "../pages/ListDating/ListDating";

const Rutas = () => {  


    return (
        <BrowserRouter>        
            <Routes>
                <Route path="/" element={<Home />} />    
                <Route path="/login" element={<Home />} />  
                <Route path="/:projectId" element={<Home />} />"      
                <Route path="/create-account/:userId" element={<CreateAccount />} />                
                <Route path="/forgot-password" element={<ForgotPassword />} />                
                <Route path={"/:projectId/create/:userId"} element={<CreateAccount />} />     
                <Route path={"/:projectId/:userId"} element={<Dashboard />}/>                                                                                                     
                <Route path={"/:projectId/:userId/edit"} element={<EditProfile />}/>
                <Route path={"/policy-advice/:projectId/:userId"} element={<PolicyAdvice />}/>
                <Route path={"/:projectId/:userId/book-appointment"} element={<BookAppointment />}/>
                <Route path={"/:projectId/:userId/list-dating"} element={<ListDating />}/>
                <Route path="*" element={<Error404 />} />
            </Routes>        
        </BrowserRouter>
    );
}
export default Rutas;