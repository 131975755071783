import { motion, AnimatePresence } from "framer-motion"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import profile from "../../../assets/social_icons/profile.png"
import generateVcard from "../../../functions/generateVcard"
import msg from "../../../assets/templates/luxury/msg.png"
import phoneI from "../../../assets/templates/luxury/phone.png"
import mailI from "../../../assets/templates/luxury/mail.png"
import linkedinI from "../../../assets/social_icons/linkedin.png"
import facebookI from "../../../assets/social_icons/facebook.png"
import instagramI from "../../../assets/social_icons/instagram.png"
import twitterI from "../../../assets/social_icons/xlogo.png"
import webI from "../../../assets/social_icons/web.png"
import { QRCodeSVG } from "qrcode.react"
import microsoftteamslg from "../../../assets/social_icons/microsoftteams.png"
import ar_logo from "../../../assets/social_icons/ra.png"

const luxurystyles = {
    a: "flex items-center justify-center w-[50px] h-[50px] rounded-lg bg-gradient-to-t from-[#92848B]/30 to-[#D9D9D9]/30 mx-2",
    menu: "w-[250px] bg-white text-black font-semibold text-center rounded-lg px-4 py-2 m-2 uppercase",
    exit: "flex items-center justify-center rounded-full bg-gradient-to-t from-[#92848B]/30 to-[#D9D9D9]/30 p-2 m-4",
}

const MainCointainer = ({ user, typeOfDevice, loginFlag, props }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, gender, projectId, microsoftteams, augmented_reality } = user
    const [showMenu, setShowMenu] = useState(false)
    const [showQR, setShowQR] = useState(false)
    const navigate = useNavigate()
    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])

    const handleMenu = () => {
        setShowMenu(!showMenu)
        setShowQR(false)
    }

    const handleQR = () => {
        setShowQR(!showQR)
    }

    const handleLogin = () => {
        navigate(projectId ? `/${projectId}` : "/")
    }

    return (
        <main className="flex flex-col items-center justify-center">
            <section style={{ backgroundImage: photo ? `url('${photo}')` : null }} className={`flex flex-col items-center ${loginFlag ? "justify-end" : "justify-end"} xl:w-[600px] lg:w-[500px] xs:w-full h-screen ${gender === "male" ? "bg-luxury-male" : "bg-luxury-female"} bg-center bg-no-repeat bg-cover relative overflow-y-hidden`}>
                <header style={{ filter: "none" }} className={`absolute top-0 w-full flex items-center z-10 justify-end ${loginFlag ? "hidden" : "block"}`}>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleLogin}
                        style={{ filter: "none" }}
                        className={luxurystyles.exit}>
                        <svg width="30" height="30" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 8.25V6.375A1.875 1.875 0 0 1 10.875 4.5h7.5a1.875 1.875 0 0 1 1.875 1.875v11.25a1.875 1.875 0 0 1-1.875 1.875H11.25c-1.036 0-2.25-.84-2.25-1.875V15.75"></path>
                            <path d="M13.5 15.75 17.25 12 13.5 8.25"></path>
                            <path d="M3.75 12H16.5"></path>
                        </svg>
                    </motion.div>
                </header>
                <div style={{ filter: (gender === "male" || photo) ? "opacity(50%) contrast(56%)" : "none" }} className="bg-black bg-center bg-no-repeat bg-cover h-full w-full absolute top-0 left-0"></div>
                <motion.button
                    onClick={handleQR}
                    title="Ver QR"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    style={{ filter: "none" }} className="flex flex-col items-start justify-center z-10 absolute top-[.5rem] left-[.5rem]">
                    <svg width="36" height="36" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#fff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                        <path fill="#fff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                        <path fill="#fff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                        <path fill="#fff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                        <path fill="#fff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                        <path fill="#fff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                        <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                        <path fill="#fff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                        <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                        <path fill="#fff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                        <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                    </svg>
                </motion.button>
                <AnimatePresence>
                    {
                        showQR &&
                        <motion.div
                            initial={{ opacity: 0, rotateZ: 180, filter: "blur(10px)", scale: 0.5 }}
                            animate={{ opacity: 1, rotateZ: 0, filter: "blur(0)", scale: 1 }}
                            whileHover={{ scale: 1.1 }}
                            exit={{ opacity: 0, rotateZ: 180, filter: "blur(10px)", scale: 0.5 }}
                            transition={{ duration: .5 }}
                            style={{ filter: "none" }}
                            className="m-4 z-20 absolute top-1/4 transform bg-white shadow-lg rounded-lg p-4"
                        >
                            <QRCodeSVG
                                value={window.location.href}
                                level="L"
                                imageSettings={{
                                    src: msg,
                                    excavate: true,
                                    x: 130,
                                    y: 130,
                                }}
                                size={typeOfDevice === "mobile" ? 300 : 300}
                            />
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {
                        !showMenu &&
                        <motion.article
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: .7 }}
                            style={{ filter: "none" }} className="flex flex-col w-full items-center z-10 absolute">
                            <div style={{ filter: "none" }} className={`flex flex-col items-center justify-center w-full my-5 ${showMenu ? "hidden" : "block"}`}>
                                <h1 style={{ filter: "none" }} className="text-4xl font-bold text-center text-white uppercase leading-none">{name && name} {lastname && lastname}</h1>
                                <h2 style={{ filter: "none" }} className="text-xl font-normal text-center text-white leading-none">{job ? job : "Puesto profesional"}</h2>
                            </div>

                            <div style={{ filter: "none" }} className={`flex flex-row w-full items-center justify-center my-5 px-4 ${showMenu ? "hidden" : "block"}`}>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={handleMenu}
                                    style={{ filter: "none" }}
                                    className={luxurystyles.menu}>
                                    Menu
                                </motion.button>
                                <motion.a
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    href={URL.createObjectURL(url)}
                                    download={`${String(name + lastname).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                    style={{ filter: "none" }}
                                    className={luxurystyles.menu}>
                                    Contacto
                                </motion.a>
                            </div>
                        </motion.article>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {
                        showMenu &&
                        <motion.article
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            exit={{ y: 100, opacity: 0 }}
                            transition={{ duration: .8 }}
                            style={{ filter: "none" }}
                            className={`w-full z-10 ${!showMenu ? "hidden" : "block"}`}>
                            <div className="flex flex-row flex-wrap px-4 my-6 items-center justify-center">
                                <motion.a
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    href={"https://wa.me/" + phone}
                                    className={luxurystyles.a}>
                                    <img src={msg} alt="msg" className="w-[40px] h-auto" />
                                </motion.a>
                                <motion.a
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    href={"tel:" + phone}
                                    className={luxurystyles.a}>
                                    <img src={phoneI} alt="msg" className="w-[40px] h-auto" />
                                </motion.a>
                                <motion.a
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    href={"mailto:" + email}
                                    className={luxurystyles.a}>
                                    <img src={mailI} alt="msg" className="w-[40px] h-auto" />
                                </motion.a>
                                <motion.a
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    href={URL.createObjectURL(url)}
                                    download={`${String(name + lastname).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                    className={luxurystyles.a}>
                                    <svg width="40" height="40" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 20c0-3 4-3 6-5 1-1-2-1-2-6 0-3.333 1.333-5 4-5s4 1.667 4 5c0 5-3 5-2 6 2 2 6 2 6 5"></path>
                                    </svg>
                                </motion.a>
                            </div>
                            <div className="bg-white flex flex-col divide-y-2 divide-solid">
                                <div className="py-6 flex flex-row items-center justify-center px-4">
                                    <img className="w-[65px] rounded-full mx-auto drop-shadow-lg" src={photo ? photo : profile} alt="profile" />
                                    <div className="w-full">
                                        <h1 className="text-2xl font-bold ml-6 uppercase leading-none">{name && name} {lastname && lastname}</h1>
                                        <h2 className="uppercase text-sm font-base ml-6">{job ? job : "No definido"}</h2>
                                    </div>
                                    <motion.button
                                        whileHover={{ rotateX: 180 }}
                                        whileTap={{ rotateZ: 90 }}
                                        onClick={handleMenu}
                                    >
                                        <svg width="36" height="36" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m5.25 8.625 6.75 6.75 6.75-6.75"></path>
                                        </svg>
                                    </motion.button>
                                </div>
                                {
                                    linkedin &&
                                    <a href={linkedin.includes("https://www.linkedin.com/in") ? linkedin : "https://www.linkedin.com/in/" + linkedin} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">Linkedin</span>
                                        <img className="w-[45px] mx-auto" src={linkedinI} alt="Linkedin" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    facebook &&
                                    <a href={
                                        typeOfDevice === "Android" ?
                                            (facebook.includes("https://www.facebook.com/") ? `fb://facewebmodal/f?href=${facebook}` : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`
                                            ) : (
                                                typeOfDevice === "iOS" ?
                                                    (facebook.includes("https://www.facebook.com/") ? `fb://profile/${facebook.replace("https://www.facebook.com/", "")}` : `fb://profile/${facebook}`) : (facebook.includes("https://www.facebook.com/") ? `${facebook}` : `https://www.facebook.com/${facebook}/`)
                                            )
                                    } target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">Facebook</span>
                                        <img className="w-[45px] mx-auto" src={facebookI} alt="Facebook" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    instagram &&
                                    <a href={instagram.includes("https://www.instagram.com/") ? `${instagram}` : `https://instagram.com/${instagram}`} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">Instagram</span>
                                        <img className="w-[45px] mx-auto" src={instagramI} alt="Instagram" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    web &&
                                    <a href={web.includes("https://") ? `${web}` : `https://${web}/`} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">Website</span>
                                        <img className="w-[45px] mx-auto" src={webI} alt="Instagram" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    twitter &&
                                    <a href={twitter.includes("https://twitter.com/") ? `${twitter}` : `https://twitter.com/${twitter}`} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">X (Twitter)</span>
                                        <img className="w-[45px] mx-auto" src={twitterI} alt="Twitter" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    microsoftteams &&
                                    <a href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">MS Teams</span>
                                        <img className="w-[45px] mx-auto" src={microsoftteamslg} alt="Instagram" width={"40px"} height={"40px"} />
                                    </a>
                                }
                                {
                                    augmented_reality &&
                                    <a href={augmented_reality} target="_blank" rel="noreferrer" className="py-6 flex flex-row items-center justify-center px-4 hover:bg-gray-50">
                                        <span className="w-full uppercase text-md font-semibold ml-6">RA</span>
                                        <img className="w-[45px] mx-auto" src={ar_logo} alt="Realidad Aumentada" width={"40px"} height={"40px"} />
                                    </a>
                                }
                            </div>
                        </motion.article>
                    }
                </AnimatePresence>
            </section>
        </main>

    )
}

export default MainCointainer