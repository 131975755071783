import React, { useContext, useEffect, useState, useMemo } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link, useParams } from "react-router-dom"
import backtoa from "../../assets/img/return.svg"
import profile from "../../assets/social_icons/profile.png"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import { Upload, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import logo from "../../assets/makinn/makinn.png"
import "../../css/sidebar.css"
import { Input, Checkbox } from "antd"
import xlogo from "../../assets/social_icons/xlogo.png"
import web_logo from "../../assets/social_icons/web.png"
import linkedid_logo from "../../assets/social_icons/linkedin.png"
import instagram_logo from "../../assets/social_icons/instagram.png"
import facebook from "../../assets/social_icons/facebook.png"
import video_logo from "../../assets/social_icons/video.png"
import msteams_logo from "../../assets/social_icons/microsoftteams.png"
import ra_logo from "../../assets/social_icons/ra.png"
import trash from "../../assets/img/trash.svg"

const EditProfile = () => {
    const { loginFlag, authStatus, searchUser, uploadPhoto, updateUser, authID, deleteSocial, uploadBanner } = useContext(FirebaseFuntions)

    const { projectId, userId } = useParams()
    const [user, setUser] = useState("")
    const [loader, setLoader] = useState(false)
    const [photo, setPhoto] = useState({})
    const [photoFile, setPhotoFile] = useState(null)
    const [bannerFile, setBannerFile] = useState(null)
    const [socials, setSocials] = useState({})
    const [isBig, setIsBig] = useState(false)
    const [alertStatus, setAlertStatus] = useState("")
    const [updateAlert, setUpdateAlert] = useState(false)
    const [isPersonal, setIsPersonal] = useState(false)
    const [typeOfF, setTypeOfF] = useState("default")
    const [isSidebar, setIsSidebar] = useState("Personalizacion")
    const [activeItem, setActiveItem] = useState("Personalizacion")
    const [animatedBehavior, setAnimatedBehavior] = useState(null)

    const handleItemClick = (item) => {
        setIsSidebar(item);
        setActiveItem(item);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const datos = Array.from(new FormData(e.target))
        const obj = Object.fromEntries(datos)
        //console.log(obj);  
        if (!isBig && photo.size !== 0) {
            const respuesta = updateUser({ ...obj, projectId, userId })
            respuesta.then((res) => {
                //console.log(res)
                setAlertStatus(res)
                handleAlert()
            })
        } else {
            const respuesta = updateUser({ ...obj, projectId, userId })
            respuesta.then((res) => {
                console.log(res)
                setAlertStatus(res)
                handleAlert()
            })
        }
        const newSocials = (obj) => {
            const newSocials = { ...socials }
            if (obj.facebook) {
                newSocials.facebook = obj.facebook
            }
            if (obj.microsoftteams) {
                newSocials.microsoftteams = obj.microsoftteams
            }
            if (obj.instagram) {
                newSocials.instagram = obj.instagram
            }
            if (obj.linkedin) {
                newSocials.linkedin = obj.linkedin
            }
            if (obj.twitter) {
                newSocials.twitter = obj.twitter
            }
            if (obj.web) {
                newSocials.web = obj.web
            }
            if (obj.video) {
                newSocials.video = obj.video
            }
            if (obj.augmented_reality) {
                newSocials.augmented_reality = obj.augmented_reality
            }
            return newSocials
        }
        setSocials(newSocials(obj))
        e.target.reset();
    }

    const handleLoader = () => {
        setTimeout(() => {
            setLoader(true)
        }, 2000)
    }

    const handleBanner = (e) => {
        const extData = String(e.type).replace("image/", ".")
        const url = URL.createObjectURL(e)
        if (e.size > 2097152) {
            setIsBig(true)
        } else {
            setIsBig(false)
            setBannerFile(url)
            const resultado = uploadBanner(e, userId + "banner" + extData, userId, projectId)
            resultado.then((res) => {
                setAlertStatus(res)
                handleAlert()
            })
        }
        return false
    }

    const handleUpload = (e) => {
        setPhoto(e)
        const extData = String(e.type).replace("image/", ".")
        const url = URL.createObjectURL(e)
        if (e.size > 2097152) {
            setIsBig(true)
        } else {
            setIsBig(false)
            setPhotoFile(url)
            const resultado = uploadPhoto(e, userId + "photo" + extData, userId, projectId)
            resultado.then((res) => {
                setAlertStatus(res)
                handleAlert()
            })
        }
        return false
    }

    const handleAlert = () => {
        setUpdateAlert(true)
        setTimeout(() => {
            return setUpdateAlert(false)
        }, 5000);
    }

    const handleDelete = (social) => {
        const datos = { ...{ social: social }, userId, projectId }
        const respuesta = deleteSocial(datos)
        respuesta.then((res) => {
            //console.log(res)
            setAlertStatus(res)
            handleAlert()
        })
        const newSocials = () => {
            const newSocials = { ...socials }
            delete newSocials[social]
            return newSocials
        }
        setSocials(newSocials())
        if (datos.social === "banner") {
            setBannerFile(null)
        }
    }

    useMemo(() => {
        if (userId || projectId) {
            const data = searchUser(userId, projectId);
            data.then((res) => {
                //console.log(res)
                setUser(res)
                setIsPersonal(res.footer === "true" ? true : false)
                setTypeOfF(res.typeoffooter ? res.typeoffooter : "default")
                setSocials({ facebook: res.facebook, instagram: res.instagram, linkedin: res.linkedin, twitter: res.twitter, web: res.web, video: res.video, microsoftteams: res.microsoftteams, augmented_reality: res.augmented_reality })
                if (res !== null) {
                    setPhotoFile(res.photo)
                    setBannerFile(res.banner ? res.banner : null)
                }
                setAnimatedBehavior(res.animated)
                //console.log(res.animated)
            })
        }
    }, [userId, projectId, searchUser])

    useEffect(() => {
        authStatus()
        handleLoader()
    }, [authStatus])

    return (
        <>
            <AnimatePresence>
                {updateAlert &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={`w-full bg-green-500 p-2 fixed top-0 flex justify-center items-center z-50`}>
                        <span className="text-md text-white font-medium uppercase text-center w-full">{alertStatus}</span>
                    </motion.div>
                }
            </AnimatePresence>
            <div className="md:w-9/12 sm:w-full sm:p-4 md:left-1/2 md:-translate-x-2/4 sm:top-0 sm:-translate-x-0 text-center mt-5 mb-5 absolute">
                {
                    loginFlag ? (
                        loader && authID === user.authID ? (
                            <>
                                <div className="text-left text-sm">
                                    <Link to={`/${projectId}/${userId}`} className="flex items-center"><img src={backtoa} alt="flecha de retorno" className="w-8 h-8 mr-2" /><span className="uppercase">Regresar</span></Link>
                                </div>
                                <motion.div
                                    className="shadow-xl rounded-lg flex md:flex-row sm:flex-col-reverse"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        default: {
                                            duration: 0.4,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        },
                                        opacity: {
                                            duration: 0.4
                                        }
                                    }}
                                >
                                    <div className="flex flex-col md:flex-row w-full">
                                        <div className="bg-black md:w-4/12 sm:w-full md:p-10 sm:p-6 border-radius-5-l">
                                            {
                                                (photoFile != null) ? (
                                                    <img src={photoFile} alt="foto de perfil" className="w-36 h-36 rounded-full mx-auto border-2 border-makinn_primary" />
                                                ) : (
                                                    <img src={profile} alt="foto de perfil" className="w-36 h-36 rounded-full mx-auto border-2 border-makinn_primary" />
                                                )
                                            }
                                            <div>
                                                {
                                                    user && (
                                                        <div className="flex flex-col items-center mt-6">
                                                            <h1 className="text-white text-2xl mb-8 font-body w-full">{user.name} {user.lastname}</h1>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="sidebar">
                                                <li className={activeItem === 'Personalizacion' ? 'active' : ''}>
                                                    <button
                                                        className={`${activeItem === 'Personalizacion' ? 'text-black font-bold' : 'text-white'} w-full`}
                                                        onClick={() => handleItemClick('Personalizacion')}
                                                    >
                                                        <svg width="40" height="40" fill={`${activeItem === 'Personalizacion' ? '#ffffff' : '#ffffff'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21.56 2.496a.754.754 0 0 0-1.09-.026l-.579.577a.375.375 0 0 0 0 .53l.532.53a.374.374 0 0 0 .531 0l.565-.562a.762.762 0 0 0 .04-1.049Z"></path>
                                                            <path d="m18.72 4.217-8.463 8.447a.42.42 0 0 0-.108.184l-.391 1.166a.183.183 0 0 0 .227.228l1.165-.392a.422.422 0 0 0 .184-.108l8.447-8.463a.422.422 0 0 0 0-.593l-.466-.47a.422.422 0 0 0-.596 0Z"></path>
                                                            <path d="m18.11 9.078-5.714 5.725c-.22.221-.492.385-.79.478l-1.215.407a1.684 1.684 0 0 1-2.078-2.078l.407-1.214c.092-.299.256-.57.477-.791l5.725-5.715a.375.375 0 0 0-.265-.64H4.875A2.625 2.625 0 0 0 2.25 7.875v11.25a2.625 2.625 0 0 0 2.625 2.625h11.25a2.625 2.625 0 0 0 2.625-2.625V9.343a.375.375 0 0 0-.64-.265Z"></path>
                                                        </svg>
                                                        Personalización
                                                    </button>
                                                </li>
                                                <li className={activeItem === 'Datos de Contacto' ? 'active' : ''}>
                                                    <button
                                                        className={`${activeItem === 'Datos de Contacto' ? 'text-black font-bold' : 'text-white'} w-full`}
                                                        onClick={() => handleItemClick("Datos de Contacto")}
                                                    >
                                                        <svg width="40" height="40" fill={`${activeItem === 'Datos de Contacto' ? '#ffffff' : '#ffffff'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.125.75H4.875A1.125 1.125 0 0 0 3.75 1.875v20.25a1.125 1.125 0 0 0 1.125 1.125h14.25a1.125 1.125 0 0 0 1.125-1.125V1.875A1.125 1.125 0 0 0 19.125.75Zm-2.864 13.911a2.015 2.015 0 1 1-4.024.21 2.015 2.015 0 0 1 4.024-.21ZM9 3h6v1.5H9V3Zm9 18h-7.5v-1.153c0-1.534 2.497-2.307 3.75-2.307 1.253 0 3.75.773 3.75 2.307V21Z"></path>
                                                        </svg>
                                                        Datos de Contacto
                                                    </button>
                                                </li>
                                                <li className={activeItem === 'Redes Sociales' ? 'active' : ''}>
                                                    <button
                                                        className={`${activeItem === 'Redes Sociales' ? 'text-black font-bold' : 'text-white'} w-full`}
                                                        onClick={() => handleItemClick("Redes Sociales")}
                                                    >
                                                        <svg width="40" height="40" fill={`${activeItem === 'Redes Sociales' ? '#ffffff' : '#ffffff'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="hover:fill-black">
                                                            <path d="M15.75 12c-.964 0-1.896-.43-2.625-1.211a4.683 4.683 0 0 1-1.219-2.86c-.082-1.154.27-2.215.991-2.988.72-.774 1.728-1.191 2.853-1.191 1.117 0 2.127.425 2.845 1.196.725.78 1.078 1.839.997 2.983-.079 1.083-.511 2.098-1.219 2.86-.728.78-1.659 1.211-2.623 1.211Z"></path>
                                                            <path d="M21.93 20.25H9.572a1.299 1.299 0 0 1-1.032-.5 1.416 1.416 0 0 1-.246-1.21c.395-1.584 1.372-2.898 2.827-3.8 1.292-.8 2.936-1.24 4.63-1.24 1.727 0 3.328.422 4.627 1.221 1.458.897 2.437 2.219 2.83 3.823a1.418 1.418 0 0 1-.25 1.208 1.298 1.298 0 0 1-1.028.498Z"></path>
                                                            <path d="M6.89 12.188c-1.65 0-3.1-1.534-3.234-3.42-.067-.965.234-1.858.843-2.513.603-.648 1.453-1.005 2.39-1.005.938 0 1.782.36 2.388 1.011.614.66.914 1.551.844 2.509-.135 1.884-1.584 3.418-3.231 3.418Z"></path>
                                                            <path d="M9.968 13.66c-.824-.404-1.894-.605-3.077-.605-1.38 0-2.722.36-3.777 1.013-1.195.742-2 1.823-2.325 3.127a1.284 1.284 0 0 0 .225 1.095 1.187 1.187 0 0 0 .942.458h5.203a.375.375 0 0 0 .369-.308.806.806 0 0 1 .019-.088c.397-1.597 1.329-2.946 2.705-3.93a.375.375 0 0 0-.03-.627 3.81 3.81 0 0 0-.254-.136Z"></path>
                                                        </svg>
                                                        Redes Sociales
                                                    </button>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="bg-white md:w-8/12 sm:w-full md:p-7 sm:p-5 border-radius-5 border border-black">
                                            <form className="flex flex-col" onSubmit={handleSubmit}>
                                                {isSidebar === "Personalizacion" && (
                                                    <div id="Personalizacion" className="flex flex-row flex-wrap items-center justify-start">
                                                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase" >Editar cuenta</h2>
                                                        <div className="flex md:flex-row sm:flex-col w-full md:mb-1 xs:mb-5">
                                                            <div className="m-1 md:w-1/2 sm:w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="file_input">Subir Foto<br /><span className="text-[12px]">(Cuida que la imagen sea cuadrada)</span></label>
                                                                <span className="text-sm text-black font-medium uppercase">{isBig && ("El archivo es demasiado pesado")}</span>
                                                                <ImgCrop rotationSlider={true} aspect={1} quality={0.7} modalTitle="Recorta tu imagen" modalOk="Guardar" modalCancel="Cancelar" >
                                                                    <Upload showUploadList={false} accept="image/png, image/jpeg, image/jpg" beforeUpload={handleUpload} className="w-full flex">
                                                                        <Button icon={<UploadOutlined />} className="bg-white w-full font-body">Seleccionar imagen</Button>
                                                                    </Upload>
                                                                </ImgCrop>
                                                                <p className="mt-1 text-sm text-black text-left font-body" id="file_input_help">PNG, JPG o JPGE (MAX. 2MB).</p>
                                                            </div>
                                                            <div className="m-1 md:w-1/2 sm:w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="file_input">Cambiar Logo<br /><span className="text-[12px]">(Considera una imagen rectangular)</span></label>
                                                                <span className="text-sm text-black font-medium uppercase">{isBig && ("El archivo es demasiado pesado")}</span>
                                                                {
                                                                    (bannerFile != null) ? (
                                                                        <div className="flex flex-row mr-4 items-center justify-between mx-auto w-full p-2 border-2 border-black rounded-lg">
                                                                            <img src={bannerFile} alt="banner" className="md:w-[230px] xs:w-[200px]" />
                                                                            <button className={`text-sm font-medium contents ${(bannerFile) ? 'block' : 'hidden'}`} onClick={() => handleDelete("banner")}>
                                                                                <svg width="27" height="27" fill="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3Zm1 2H6v12h12V8Zm-9 3h2v6H9v-6Zm4 0h2v6h-2v-6ZM9 4v2h6V4H9Z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <ImgCrop quality={0.4} modalTitle="Recorta tu imagen" modalOk="Guardar" modalCancel="Cancelar" aspect={15 / 2} rotationSlider aspectSlider showReset fillColor="transparent" >
                                                                            <Upload showUploadList={false} accept="image/png, image/jpeg, image/jpg" beforeUpload={handleBanner} className="w-full flex">
                                                                                <Button icon={<UploadOutlined />} className="bg-white w-full font-body">Seleccionar logo</Button>
                                                                            </Upload>
                                                                        </ImgCrop>
                                                                    )
                                                                }
                                                                <p className="mt-1 text-sm text-black text-left font-body" id="file_input_help">PNG, JPG o JPGE (MAX. 2MB).</p>
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg my-3">
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase font-body" htmlFor="template">Selecciona un tema</label>
                                                                <div className="relative">
                                                                    <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body" id="template" name="template" defaultValue={user.template} >
                                                                        <option value="promo" >Default</option>
                                                                        <option value="luxury">Elegante</option>
                                                                        {
                                                                            (user?.projectId === "bh" || user?.role === "superadmin") && (
                                                                                <option value="bh">BH Trade Market</option>
                                                                            )
                                                                        }
                                                                        {
                                                                            (user?.projectId === "voestalpine") && (
                                                                                <option value="voestalpine">Voestalpine</option>
                                                                            )
                                                                        }
                                                                        {
                                                                            (user?.projectId === "makinn" && user?.userId === "usuario_0") && (
                                                                                <option value="wen">Wen Rodríguez</option>
                                                                            )
                                                                        }
                                                                        {
                                                                            (user?.projectId === "carnot") && (
                                                                                <option value="carnot">Carnot</option>
                                                                            )
                                                                        }
                                                                        {
                                                                            (user?.projectId === "procaps") && (
                                                                                <option value="procaps">Procaps</option>
                                                                            )
                                                                        }
                                                                        {
                                                                            (user?.projectId === "ollamani") && (
                                                                                <option value="ollamani">Ollamani</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m6 9 6 6 6-6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase font-body" htmlFor="footer">Pie de página</label>
                                                                <div className="relative">
                                                                    <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                                                        id="footer" name="footer" defaultValue={user?.footer} onChange={(e) => {
                                                                            setIsPersonal(e.target.value === "true" ? true : false)
                                                                        }}>
                                                                        <option value={true} >Si</option>
                                                                        <option value={false}>No</option>
                                                                    </select>
                                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m6 9 6 6 6-6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg font-body my-3 ${(isPersonal) ? "block" : "hidden"}`}>
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase" htmlFor="footer">Tipo de texto personalizado</label>
                                                                <div className="relative">
                                                                    <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body" id="typeoffooter" name="typeoffooter" defaultValue={user?.typeoffooter} onChange={(e) => {
                                                                        setTypeOfF(e.target.value)
                                                                    }}>
                                                                        <option value="defaul" >Default</option>
                                                                        <option value="personalized">Texto personalizado</option>
                                                                    </select>
                                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m6 9 6 6 6-6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase" htmlFor="footer">Texto personalizado</label>
                                                                <input className={`appearance-none w-full rounded-md border border-black py-2 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body ${typeOfF !== 'personalized' && 'opacity-50 border border-gray-400'}`} type="text" id="footer_text" name="footer_text" placeholder="Texto personalizado" defaultValue={user?.footer_text} disabled={(typeOfF === "personalized") ? false : true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {isSidebar === "Datos de Contacto" && (
                                                    <div id="Datos de Contacto" className="flex flex-row flex-wrap items-center justify-start">
                                                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase" >Datos de contacto</h2>
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="name">Nombre</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="name" name="name" placeholder="Nombre" defaultValue={user?.name} />
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="lastname">Apellido</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="lastname" name="lastname" placeholder="Apellido" defaultValue={user?.lastname} />

                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="phone">Whatsapp</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="number" id="phone" name="phone" placeholder="whatsapp" defaultValue={user?.phone} />
                                                        <div className="flex flex-row w-full items-center justify-center">
                                                            <Checkbox
                                                                className="m-3" type="checkbox" id="whats_animated" name="whats_animated" defaultChecked={animatedBehavior?.whats_animated ? true : false}
                                                                onChange={(e) => {
                                                                    setAnimatedBehavior({ "whats_animated": e.target.checked })
                                                                }}
                                                                value={animatedBehavior?.whats_animated ? true : false}
                                                            />
                                                            <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="whats_animated">Animación del icono</label>
                                                        </div>
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="email">Correo Electrónico</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="email" name="email" placeholder="Correo Electrónico" defaultValue={user?.email} />
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="job">Puesto de trabajo</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="job" name="job" placeholder="Puesto de trabajo" defaultValue={user?.job} />
                                                        <label className="mb-2 text-sm text-black font-medium text-left uppercase hidden" htmlFor="gender">Genero</label>
                                                        <select className="m-3 w-full rounded-lg p-3 focus:outline-none hidden" id="gender" name="gender">
                                                            <option value="male">Hombre</option>
                                                            <option value="female">Mujer</option>
                                                        </select>
                                                    </div>
                                                )}
                                                {isSidebar === "Redes Sociales" && (
                                                    <>
                                                        {Object.values(socials).some((value) => value) && (
                                                            <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">Redes Sincronizadas</h2>
                                                        )}
                                                        {(Object.values(socials).every((value) => value === null || value === undefined || value === '')) && (
                                                            <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">Redes Sociales (Opcionales)</h2>
                                                        )}
                                                        {/* REDES ASOCIADAS */}
                                                        {
                                                            user && (
                                                                <>
                                                                    {socials.web &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={web_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Sitio Web</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("web")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.web.includes("https://") ? socials.web.replace("https://", "") : socials.web}</p>
                                                                        </div>}
                                                                    {socials.linkedin &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={linkedid_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">LinkedIn</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("linkedin")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.linkedin.includes("https://www.linkedin.com/in/") ? socials.linkedin.replace("https://www.linkedin.com/in/", "") : socials.linkedin}</p>
                                                                        </div>}
                                                                    {socials.twitter &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={xlogo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">X (Twitter)</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("twitter")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.twitter.includes("https://twitter.com/") ? socials.twitter.replace("https://twitter.com/", "@") : socials.twitter}</p>
                                                                        </div>}
                                                                    {socials.instagram &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={instagram_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Instagram</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("instagram")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.instagram.includes("https://www.instagram.com/") ? socials.instagram.replace("https://www.instagram.com/", "@") : socials.instagram}</p>
                                                                        </div>}
                                                                    {socials.facebook &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={facebook} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Facebook</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("facebook")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.facebook.includes("https://www.facebook.com/") ? socials.facebook.replace("https://www.facebook.com/", "") : socials.facebook}</p>
                                                                        </div>}
                                                                    {socials.video &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={video_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Video</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("video")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.video.includes("https://www.youtube.com/watch?v=") ? socials.video.replace("https://www.youtube.com/watch?v=", "") : socials.video}</p>
                                                                        </div>}
                                                                    {socials.microsoftteams &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={msteams_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Microsoft Teams</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("microsoftteams")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.microsoftteams.includes("https://www.youtube.com/watch?v=") ? socials.microsoftteams.replace("https://www.youtube.com/watch?v=", "") : socials.microsoftteams}</p>
                                                                        </div>}
                                                                    {socials.augmented_reality &&
                                                                        (user?.projectId === "bh" || user?.projectId === "promo") ? (
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={ra_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Realidad Aumentada</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("augmented_reality")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.augmented_reality}</p>
                                                                        </div>) : null}
                                                                </>
                                                            )
                                                        }
                                                        {/* REDES SOCIALES POR ASOCIAR */}
                                                        {socials.web ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="web">Sitio Web</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="web" name="web" placeholder="Sitio Web" defaultValue={socials.web} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo. Por ejemplo: https://www.misitio.com</p>
                                                            </div>
                                                        )}
                                                        {socials.video ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="video">Video</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="video" name="video" placeholder="URL del video" defaultValue={socials.video} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo.</p>
                                                            </div>
                                                        )}
                                                        {socials.linkedin ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="linkedin">LinkedIn</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="linkedin" name="linkedin" placeholder="Usuario de LinkedIn" defaultValue={socials.linkedin} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">linkedin.com/in/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.twitter ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="twitter">X (Twitter)</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="twitter" name="twitter" placeholder="Usuario de Twitter" defaultValue={socials.twitter} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">twitter.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.instagram ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="instagram">Instagram</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="instagram" name="instagram" placeholder="Usuario de Instagram" defaultValue={socials.instagram} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">instagram.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.facebook ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="facebook">Facebook</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="facebook" name="facebook" placeholder="Usuario de Facebook" defaultValue={socials.facebook} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">facebook.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.microsoftteams ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="microsoftteams">Microsoft Teams</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="microsoftteams" name="microsoftteams" placeholder="URL de Microsoft Teams" defaultValue={socials.microsoftteams} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">teams.microsoft.com/l/chat/0/0?users=user_email
                                                                </span> únicamente ingresar <span className="font-bold">chat/0/0?users=user_email</span></p>
                                                            </div>
                                                        )}
                                                        {(!socials.augmented_reality && (projectId === "bh" || projectId === "promo")) ? (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="augmented_reality">Realidad Aumentada</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="augmented_reality" name="augmented_reality" placeholder="URL de Microsoft Teams" defaultValue={socials.augmented_reality} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo.</p>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                                <motion.button
                                                    whileHover={{ scale: 1.03 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                                    className="bg-black text-white font-bold m-3 py-2 px-4 rounded-lg uppercase focus:outline-none font-body"
                                                    type="submit"
                                                >
                                                    Guardar Cambios
                                                </motion.button>
                                            </form>
                                        </div>
                                    </div>
                                </motion.div>
                            </>
                        ) : (
                            <AnimatePresence>
                                <div className="md:w-4/12 sm:w-auto top-full left-1/2 -translate-x-2/4 translate-y-full text-center absolute">
                                    <motion.img
                                        initial={{ rotateY: 0 }}
                                        animate={{ rotateY: 360 }}
                                        transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                        exit={{ opacity: 0 }}
                                        src={logo}
                                        alt="logo makinn"
                                        className="mx-auto mb-5"
                                        width={150} height={150}
                                    />
                                    <p className="uppercase text-lg">Cargando</p>
                                </div>
                            </AnimatePresence>
                        )
                    ) : (
                        //centra el h1 
                        <div className="w-full h-screen flex flex-col justify-center items-center">
                            <h1 className="text-black text-center text-xl mb-5 font-bold">Necesitas iniciar sesión</h1>
                            <Link className="bg-makinn_primary rounded-xl py-3 px-5 font-bold uppercase text-white" to={`/${projectId}`} >Ir ahora</Link>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default EditProfile;
