import React, { useContext } from "react";
import { motion } from "framer-motion";
import { FirebaseFuntions } from "../../../context/FirebaseContext";
import { Link, useNavigate } from "react-router-dom";

const luxurystyles = {
    a : "flex items-center justify-center w-[50px] h-[50px] rounded-lg bg-gradient-to-t from-[#92848B]/30 to-[#D9D9D9]/30 mx-2",
    menu: "w-[250px] bg-white text-black font-semibold text-center rounded-lg px-4 py-2 m-2 uppercase",
    exit: "flex items-center justify-center rounded-full bg-gradient-to-t from-[#92848B]/30 to-[#D9D9D9]/30 p-2 m-4",
}

const Header = ({props, loginFlag}) => {

    const { userId, projectId } = props;
    const { userLogout } = useContext(FirebaseFuntions);
    const navigate = useNavigate();
    
    return (
        <header className="flex flex-col items-center justify-center">   
            <div className={`flex justify-end items-center xl:w-[600px] lg:w-[500px] xs:w-full bg-black`}>
                <motion.div
                    whileHover={{scale: 1.1}}
                    whileTap={{scale: 0.9}}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                    <Link to={`/${projectId}/${userId}/edit`}>
                        <svg width="25" height="25" fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8.25A3.75 3.75 0 1 0 15.75 12 3.761 3.761 0 0 0 12 8.25ZM20.094 12c-.002.35-.027.7-.076 1.047l2.282 1.787a.543.543 0 0 1 .123.693l-2.159 3.727a.546.546 0 0 1-.663.231l-2.683-1.078a8.27 8.27 0 0 1-1.82 1.063l-.401 2.85a.56.56 0 0 1-.54.461H9.84a.562.562 0 0 1-.54-.447l-.4-2.849a7.94 7.94 0 0 1-1.82-1.063L4.396 19.5a.545.545 0 0 1-.663-.23l-2.159-3.728a.543.543 0 0 1 .123-.692l2.283-1.787A8.182 8.182 0 0 1 3.903 12c.002-.35.027-.7.077-1.047L1.697 9.166a.543.543 0 0 1-.123-.693l2.16-3.727a.546.546 0 0 1 .662-.231L7.08 5.593A8.276 8.276 0 0 1 8.9 4.53l.4-2.85a.56.56 0 0 1 .54-.461h4.318a.563.563 0 0 1 .54.446l.4 2.85c.653.271 1.266.63 1.823 1.063L19.602 4.5a.546.546 0 0 1 .663.23l2.16 3.728a.543.543 0 0 1-.124.693l-2.282 1.787c.048.352.074.707.076 1.062Z"></path>
                        </svg>
                    </Link>
                </motion.div>               
                <motion.button
                    whileHover={{scale: 1.1}}
                    whileTap={{scale: 0.9}}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}                
                    className={luxurystyles.exit}
                    onClick={() => {
                        userLogout()
                        setTimeout(() => {
                            navigate(`/${projectId}/${userId}`);
                        },500);                                        
                    }}
                    >
                        <svg width="25" height="25" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 15.75v1.875a1.875 1.875 0 0 1-1.875 1.875h-7.5A1.875 1.875 0 0 1 3 17.625V6.375A1.875 1.875 0 0 1 4.875 4.5H12c1.036 0 2.25.84 2.25 1.875V8.25"></path>
                            <path d="M17.25 15.75 21 12l-3.75-3.75"></path>
                            <path d="M8.25 12h12"></path>
                        </svg>
                </motion.button>
            </div> 
        </header>
    );
};

export default Header;