import React, { useContext } from "react"
import { motion } from "framer-motion"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import { Link, useNavigate } from "react-router-dom"
const Header = ({ props }) => {

    const { userId, projectId } = props
    const { userLogout } = useContext(FirebaseFuntions)
    const navigate = useNavigate()

    return (
        <div className="flex justify-end items-center">
            {/* {projectId === "promo" &&
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    className="mr-5"
                >
                    <Link to={`/${projectId}/${userId}/list-dating`} title="Citas agendadas">
                        <svg width="27" height="27" fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 4h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2Zm-1 15h-6v-6h6v6Zm1-10H5V7h14v2Z"></path>
                        </svg>
                    </Link>
                </motion.div>
            } */}
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
                <Link to={`/${projectId}/${userId}/edit`} title="Editar perfil">
                    <svg width="27" height="27" fill="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.954 2.213a9.99 9.99 0 0 1 4.091-.002A3.994 3.994 0 0 0 16 5.073a3.993 3.993 0 0 0 3.457.26A9.99 9.99 0 0 1 21.5 8.88a3.994 3.994 0 0 0-1.5 3.124 3.99 3.99 0 0 0 1.502 3.124 10.042 10.042 0 0 1-2.046 3.543 3.993 3.993 0 0 0-4.76 1.468 3.993 3.993 0 0 0-.65 1.653 9.992 9.992 0 0 1-4.09.004A3.993 3.993 0 0 0 8 18.93a3.992 3.992 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.124a3.994 3.994 0 0 0 1.106-4.856 3.993 3.993 0 0 0-1.108-1.39 10.043 10.043 0 0 1 2.046-3.543 3.993 3.993 0 0 0 4.76-1.468 4 4 0 0 0 .65-1.653v-.001ZM12 15.003a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                    </svg>
                </Link>
            </motion.div>
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="bg-black text-white text-sm m-5 px-4 py-2 rounded-xl flex flex-row items-center uppercase focus:outline-none font-body"
                onClick={() => {
                    userLogout()
                    setTimeout(() => {
                        navigate(`/${projectId}/${userId}`)
                    }, 500);
                }}
            >
                <span className="mr-4">
                    Cerrar sesión
                </span>
                <svg width="27" height="27" fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3Zm2-7h7v2H6v3l-5-4 5-4v3Z"></path>
                </svg>
            </motion.button>
        </div>
    );
};

export default Header;
