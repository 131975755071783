import Rutas from "./routes/Rutas";
import FirebaseContext from "./context/FirebaseContext";
import "../src/css/main.css";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3E3CDF",
        },
      }}
    >
      <FirebaseContext>
        <Rutas />
      </FirebaseContext>
    </ConfigProvider>
  );
}

export default App;
