import React from "react"
import { NavLink, useParams } from "react-router-dom"

const PolicyAdvice = () => {

    const { projectId, userId } = useParams()

    return (
        <article className="p-4 bg-[#F9FAFB]" >
            <div className="md:p-10 sm:p-5 bg-white md:w-[800px] sm:w-[320px] left-2/4 -translate-x-1/2 relative">
                <h1 className="md:text-5xl sm:text-3xl font-bold " >Política de Privacidad</h1>            
                <p className="md:text-lg sm:text-base font-medium"> México, Septiembre 2022 </p>
                <h2 className="md:text-2xl sm:text-xl mt-2 mb-1 font-bold" > DISPOSICIONES GENERALES</h2>
                <p className="text-justify md:text-base sm:text-sm" > En base a la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados y de su respectivo Reglamento, MAKINN, en conformidad con las disposiciones emitidas, se da a conocer en este aviso de privacidad como pueden ser utilizados y divulgados sus datos personales y como puede tener acceso a ellos, si tiene alguna duda favor de acudir al área de Responsable de Información, ubicado en San Andrés Atoto No. 155 Piso 1 Local B Unidad San Esteban, 53550 Naucalpan de Juárez, México, o en su caso enviar un mensaje en https://www.makinntap.com/ </p>
                <h2 className="md:text-2xl sm:text-xl mt-2 mb-1 font-bold" > DISPOSICIONES LEGALES</h2>
                <p className="text-justify md:text-base sm:text-sm"> La Ley Federal de protección de Datos Personales en Posesión de particulares, requiere que se proteja la privacidad de los datos personales y se apliquen las prácticas establecidas por MAKINN, que se describen en este aviso. Los datos personales que maneja esta Institución son considerados Datos Personales Sensibles<br/>
                    Con la finalidad de proporcionar servicios de calidad, se establece en MAKINN acatar la normatividad establecida con respecto a sus datos personales, la ley vigente exige que se realicen todos los esfuerzos necesarios y razonables para mantener la privacidad de su información.<br/>
                    MAKINN está obligado a explicar sus políticas legales y procedimientos de privacidad respecto a datos personales.</p>
                <h2 className="md:text-2xl sm:text-xl mt-2 mb-1 font-bold" >ALMACENAMIENTO Y RESGUARDO DE INFORMACION</h2>
                <p className="text-justify md:text-base sm:text-sm"> MAKINN, cuenta con un Sistema Integral de Registro vía Web, el cual almacena los datos personales en una base de datos digitales, el acceso a los servidores y a la base de datos están protegidos por contraseñas y pasos restringidos, y no puede ser consultada por usuarios no autorizados del personal  o de la Institución, se ha capacitado al personal de MAKINN, en el manejo adecuado de la información de los datos personales, con el fin de impedir que terceros no autorizados accedan a la misma.<br/>
                    La información es almacenada en discos duros del servidor y se hacen respaldos diarios, con la finalidad de garantizar la integridad de esta, aún en caso de desastre, los sitios cuentan con seguridad y los sistemas cuentan con encriptación adecuada para las funciones.<br/>
                    Los proveedores a quienes se les proporciona información firman un convenio de confidencialidad.</p>
                <h2 className="md:text-2xl sm:text-xl mt-2 mb-1 font-bold" >USO DE DATOS PERSONALES</h2>
                <p className="text-justify md:text-base sm:text-sm"> MAKINN, maneja los datos personales y por diferentes motivos que se mencionan a continuación.</p>
                <p className="text-justify md:text-base sm:text-sm mb-10"> Información de uso interno. Análisis y validación de los datos generales, con el objeto de conocer a la empresa o persona, así como otros reportes administrativos de uso interno.<br/>
                    Tratamiento del expediente. Se comparten datos personales con compañías y otras personas que están involucradas con el análisis de la información y prestación de los servicios.<br/>
                    Actividades relacionadas. Se podrán utilizar sus datos personales con los contadores, abogados y empresas que evalúan y verifican que se están cumpliendo con las diferentes normas y leyes vigentes.<br/>
                    Otros Usos de sus Datos Personales. MAKINN, está obligada a que nos otorguen autorización por escrito, para cualquier uso de sus datos personales no descritos, teniendo la posibilidad de retirar posteriormente la autorización y no permitir ningún uso futuro por escrito.<br/>                    
                    Derecho a corregir o actualizar sus datos personales. MAKINN, si identifica que hay un error en sus datos personales o falta información importante, podrá solicitar que se corrija o se amplié.<br/>
                    Derechos de Protección de Datos Personales. El titular de los datos o representante legal debidamente acreditado podrá limitar el uso o divulgación de sus datos personales, a partir del 17 de febrero del 2022, podrá ejercer, cuando proceda, los derechos de acceso, rectificación, cancelación u oposición que la ley prevé, mediante solicitud por escrito a MAKINN Por otra parte, se le informa que tiene derecho a iniciar un procedimiento de Protección de Datos ante el Instituto de Acceso a la Información y Protección de Datos.<br/>
                    Modificación al aviso de privacidad. Cualquier modificación a este aviso será publicada en la página Web https://www.makinntap.com/ , o un mensaje enviado a su correo electrónico.<br/>
                    Quejas y sugerencias con respecto al Aviso de Privacidad. Cualquier pregunta relacionada con este aviso, desea presentar queja contra las prácticas de privacidad de MAKINN, y que se han violado sus derechos de privacidad, favor de comunicarse al departamento Responsable de la Información, ubicado en San Andrés Atoto No. 155 Piso 1 Local B Unidad San Esteban, 53550 Naucalpan de Juárez, México, o en su caso enviar un mensaje en https://www.makinntap.com/<br/>
                    Aceptación de los términos. Esta declaración de Privacidad está sujeta a los términos y condiciones del sitio web https://www.makinntap.com/  antes descrito, lo cual constituye un acuerdo legal entre el usuario y MAKINN.<br/>
                    El uso de nuestros Servicios confirmará su aceptación de tales cambios o modificaciones, por lo que debe revisar con frecuencia los términos, políticas y modificaciones aplicables para entender las condiciones que se aplican a su uso de los Servicios. Si no acepta las condiciones modificadas, deberá dejar de utilizar los Servicios.
                </p>
                <NavLink  className="bg-makinn_primary rounded-xl py-3 px-5 font-bold uppercase text-white" to={`/${projectId}/create/${userId}`} >Regresar</NavLink>
            </div>            
        </article>
    )
}

export default PolicyAdvice