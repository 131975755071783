import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import logomakinn from "../../assets/makinn/makinn_row.png"
import logopromo from "../../assets/promolife/promoconnecta.png"
import profile from "../../assets/social_icons/profile.png"
import linkedinImg from "../../assets/social_icons/linkedin.png"
import contact from "../../assets/social_icons/contact.png"
import email_ from "../../assets/social_icons/email.png"
import phoneImg from "../../assets/social_icons/phone.png"
import whatsapp from "../../assets/social_icons/whatsapp.png"
import facebookp from "../../assets/social_icons/facebook.png"
import instagramp from "../../assets/social_icons/instagram.png"
import twitterp from "../../assets/social_icons/xlogo.png"
import microsoftteamslg from "../../assets/social_icons/microsoftteams.png"
import videop from "../../assets/social_icons/video.png"
import generateVcard from "../../functions/generateVcard"
import QrModal from "../QrModal/QrModal"
import { Link } from "react-router-dom"
import wen from "../../assets/templates/wen/logo_wen_black.png"
import logo_carnot from "../../assets/templates/carnot/carnot_logo_color.png"
import ar_logo from "../../assets/social_icons/ra.png"
import logo_ollamani from "../../assets/templates/ollamani/ollamani_logo.png"

const socialStyle = "flex flex-col md:m-6 md:w-24 sm:m-3 sm:w-3/12 drop-shadow-lg"
const labelStyle = "mt-3 text-center sm:text-sm md:text-base font-body"

const MainCointainer = ({ user, typeOfDevice, projectLogo }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, banner, footer, job, footer_text, typeoffooter, video, animated, linkbuttom, projectId, userId, microsoftteams, augmented_reality } = user

    const [showQr, setShowQr] = useState(false)

    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])

    const handleQR = () => {
        setShowQr(!showQr)
    }

    return (
        <main className="flex flex-col justify-around md:p-20 sm:p-5">
            <section className="flex md:justify-start sm:justify-center mb-8">
                <div className="md:w-2/5 sm:w-full flex items-center justify-center">
                    <motion.img
                        initial={{ opacity: 0, y: -100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        className="md:scale-100 sm:scale-50 md:max-w-[400px] max-h-[160px] sm:max-w-[300px]"
                        src={
                            projectId === "makinn" && userId === "usuario_0" ? wen :
                            projectId === "carnot" ? logo_carnot : banner ? banner :
                            projectId === "ollamani" ? logo_ollamani : banner ? banner :
                            (projectLogo?.logo ? projectLogo?.logo : logomakinn)} alt="logo" />
                </div>
            </section>
            <section className="flex md:justify-start md:flex-row sm:flex-col  sm:justify-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                <div className="w-full flex justify-center items-center md:flex-col sm:flex-col md:w-2/5 sm:w-full">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        className="flex flex-col items-end justify-center">
                        <img className="md:w-56 md:mt-0 sm:mt-5 sm:w-28 rounded-xl mx-auto drop-shadow-lg" src={photo ? photo : profile} alt="profile" />
                        <motion.button
                            onClick={handleQR}
                            title="Ver QR"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            style={{ filter: "none" }} className={`flex flex-col items-start justify-center z-10 bg-black rounded-full p-2 relative ${photo ? '-top-4 left-4' : '-top-6 -left-12'}`}>
                            <svg width="20" height="20" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill="#fff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                <path fill="#fff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                <path fill="#fff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                            </svg>
                        </motion.button>
                    </motion.div>
                    {projectId === "promo" ?
                        <Link to={`/${projectId}/${userId}/book-appointment`}>
                            <motion.button
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                type="button"
                                className="text-promolife border border-promolife hover:text-white hover:bg-promolife focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center mt-5 md:mt-0"
                            >
                                <svg width={20} height={20} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 3h-3V1.5H15V3H9V1.5H7.5V3h-3C3.675 3 3 3.675 3 4.5v15c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5v-15c0-.825-.675-1.5-1.5-1.5Zm0 16.5h-15V9h15v10.5Zm0-12h-15v-3h3V6H9V4.5h6V6h1.5V4.5h3v3Z" />
                                </svg>
                                <span className="ml-2">Agendar Cita</span>
                            </motion.button>

                        </Link>
                        : null}
                    <div className="w-full flex flex-col justify-center items-center p-4">
                        <div className="leading-tight mb-4 flex w-full flex-col justify-center items-center">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center uppercase font-body">{job}</motion.h2>

                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="md:text-2xl sm:text-lg text-center" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="ml-2 color-texto-web font-body">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                            {
                                projectLogo?.linkbuttom &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="px-4 py-4 border-2 border-black rounded-2xl uppercase mt-4 hover:bg-black hover:text-white w-full items-center justify-center flex w-[300px]"
                                    href={projectLogo?.linkbuttom?.link}
                                >
                                    {projectLogo?.linkbuttom?.name}
                                </motion.a>
                            }
                            {
                                linkbuttom &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                                    className="px-4 py-4 border-2 border-black rounded-2xl uppercase mt-4 hover:bg-black hover:text-white w-full items-center justify-center flex w-[300px]"
                                    href={linkbuttom?.link}
                                >
                                    {linkbuttom?.name}
                                </motion.a>
                            }
                            {
                                projectId === "promo" &&
                                <motion.a

                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    //transition={{duration: 1, ease: "easeInOut", delay: 1.6}}
                                    className="p-2 border-2 border-black rounded-2xl uppercase mt-4 w-full items-center justify-center flex w-[300px]"
                                    href={"https://promoconnecta.com/"}
                                    target="_blank"
                                >
                                    <img src={logopromo} alt="Promoconnecta" className="xl:w-[120px] xs:w-[100px]" />
                                </motion.a>
                            }
                        </div>
                    </div>
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                    className="flex flex-row flex-wrap justify-center items-center p-5 md:w-3/5 sm:w-full md:mt-0 sm:mt-3">
                    <a
                        className={socialStyle} href={"tel:" + phone}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={phoneImg} alt="Teléfono" />
                        <p className={labelStyle}>Teléfono</p></a>
                    <a
                        className={socialStyle + ` ${animated?.whats_animated && 'animate-bounce'} `} href={"https://wa.me/" + phone}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={whatsapp} alt="Whatsapp" />
                        <p className={labelStyle}>WhatsApp</p></a>
                    <a
                        className={socialStyle} href={URL.createObjectURL(url)} download={`${String(name + lastname).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.vcf`}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={contact} alt="Contacto" />
                        <p className={labelStyle}>Contacto</p></a>
                    <a
                        className={socialStyle} href={"mailto:" + email}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={email_} alt="Email" />
                        <p className={labelStyle}>Email</p></a>
                    {linkedin && <a className={socialStyle} href={linkedin.includes("https://www.linkedin.com/in") ? linkedin : "https://www.linkedin.com/in/" + linkedin}><motion.img
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        src={linkedinImg} alt="Linkedin" />
                        <p className={labelStyle}>LinkedIn</p></a>}
                    {facebook && <a className={socialStyle} href={
                        typeOfDevice === "Android" ? (facebook.includes("https://www.facebook.com/") ? `fb://facewebmodal/f?href=${facebook}` : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`) : (
                            typeOfDevice === "iOS" ? (facebook.includes("https://www.facebook.com/") ? `fb://profile/${facebook.replace("https://www.facebook.com/", "")}` : `fb://profile/${facebook}`) : (facebook.includes("https://www.facebook.com/") ? `${facebook}` : `https://www.facebook.com/${facebook}/`)
                        )
                    } >
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={facebookp} alt="Facebook" />
                        <p className={labelStyle}>Facebook</p></a>}
                    {instagram && <a className={socialStyle} href={instagram.includes("https://www.instagram.com/") ? `${instagram}` : `https://instagram.com/${instagram}`} >
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={instagramp} alt="Instagram" />
                        <p className={labelStyle}>Instagram</p></a>}
                    {twitter && <a className={socialStyle} href={twitter.includes("https://twitter.com/") ? `${twitter}` : `https://twitter.com/${twitter}`}>
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={twitterp} alt="Twitter" />
                        <p className={labelStyle}>X (Twitter)</p></a>}
                    {video && <a className={socialStyle} href={`${video}`} target="_blank" rel="noreferrer">
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={videop} alt="VIDEO" />
                        <p className={labelStyle}>VIDEO</p></a>}
                    {microsoftteams &&
                        <a className={socialStyle}
                            href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams}
                            target="_blank"
                            rel="noreferrer">
                            <motion.img
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                src={microsoftteamslg} alt="Microsoft Teams" />
                            <p className={labelStyle}>MS Teams</p>
                        </a>
                    }
                    {augmented_reality &&
                        <a className={socialStyle}
                            href={augmented_reality}
                            target="_blank"
                            rel="noreferrer">
                            <motion.img
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                src={ar_logo} alt="Realidad Aumentada" />
                            <p className={labelStyle}>RA</p>
                        </a>
                    }
                </motion.div>
            </section>
            <section className={`${(!footer || footer === 'true') ? 'flex' : 'hidden'} md:justify-end  sm:justify-center md:mt-24 sm:mt-10`}>
                <motion.span
                    initial={{ opacity: 0, y: -100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}

                    className="md:text-2xl sm:text-lg md:text-right sm:text-center italic">
                    {
                        projectLogo?.logo && projectId !== "promo" ?
                            null
                            :
                            (footer_text && footer_text !== "" && typeoffooter !== 'defaul' ?
                                footer_text
                                :
                                "Mejoramos las experiencias y fortalecemos las conexiones interpersonales")
                    }
                </motion.span>
            </section>
        </main>
    )
}

export default MainCointainer