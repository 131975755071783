import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import procaps_logo from "../../../assets/templates/procaps/procaps_logo.png"
import profile from "../../../assets/social_icons/profile.png"
import linkedinImg from "../../../assets/social_icons/linkedin.png"
import email_ from "../../../assets/social_icons/email.png"
import phoneImg from "../../../assets/social_icons/phone.png"
import whatsapp from "../../../assets/social_icons/whatsapp.png"
import facebookp from "../../../assets/social_icons/facebook.png"
import instagramp from "../../../assets/social_icons/instagram.png"
import twitterp from "../../../assets/social_icons/xlogo.png"
import videop from "../../../assets/social_icons/video.png"
import microsoftteamsp from "../../../assets/social_icons/microsoftteams.png"
import generateVcard from "../../../functions/generateVcard"
import QrModal from "../../../components/QrModal/QrModal"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vadecum from "../../../assets/templates/procaps/vadecum.png"


const MainCointainer = ({ user, typeOfDevice, projectLogo, loginFlag }) => {

    console.log("loginFlag", loginFlag)

    //loginFlag es un nooleano, dame una condicon para comproar si es falso o verdadero

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, video, microsoftteams, animated } = user

    const [showQr, setShowQr] = useState(false)

    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])

    const handleQR = () => {
        setShowQr(!showQr)
    }

    const socialStyle = "flex justify-center drop-shadow-lg mt-2"
    const imageStyle = "xl:w-[120px] xl:h-[120px] sm:w-20 sm:h-20 flex content-center p-2"

    const socialLinks = [
        phone,
        email,
        linkedin,
        facebook,
        instagram,
        twitter,
        video,
        microsoftteams
    ].filter(link => link !== undefined && link !== null);

    let infinite = false;

    if (socialLinks.length >= 4) {
        infinite = true;
    }
    if (socialLinks.length <= 3) {
        infinite = false;
    }

    const sliderSettings = {
        dots: false, // Muestra los puntos de navegación
        infinite: infinite, // Permite un desplazamiento infinito
        speed: 500, // Velocidad de transición en milisegundos
        slidesToShow: 3, // Número de elementos mostrados a la vez
        slidesToScroll: 1, // Número de elementos desplazados en cada cambio
        // centerMode: false, // Centra el elemento activo
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos más pequeños
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos aún más pequeños
                },
            },
        ],
    };

    return (
        <main className="flex flex-col justify-evenly md:p-20 sm:p-5 bg-white xl:h-full lg:h-full md:h-screen xs:h-auto">
            <section className="flex flex-col justify-center items-center">
                <motion.img
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className="mx-auto" src={projectLogo ? projectLogo : procaps_logo} alt="carnot" />
            </section>

            <section className="flex md:justify-center md:flex-col sm:flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                {/* INFORMACION PERSONAL */}
                <div className="relative w-full">
                    <div className="absolute bg-procaps border border-procaps w-full rounded-lg shadow-lg top-1/4" style={{ height: "78%" }}>
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}>
                        </motion.div>
                    </div>
                    <>
                        <motion.div
                            className="flex flex-col items-center justify-center">
                            <img className="md:w-56 md:mt-0 sm:mt-5 sm:w-40 rounded-full mx-auto drop-shadow-lg border-4 border-procaps_900"
                                src={photo ? photo : profile} alt="profile" />
                            {/* <QR></QR> */}
                            <motion.button
                                onClick={handleQR}
                                title="Ver QR"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ filter: "none" }}
                                className="relative flex flex-col items-center justify-center bg-procaps_900 rounded-full p-2 -top-5">
                                <svg width="20" height="20" fill="none" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                                </svg>
                            </motion.button>
                        </motion.div>
                        {/* DATOS */}
                        <div className="leading-tight mb-4 flex flex-col justify-center items-center space-y-3 xs:mt-3 lg:mt-0">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center text-white  font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center text-white uppercase font-body">{job}</motion.h2>

                            }
                            {
                                <motion.button
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="bg-procaps_900 text-white font-bold py-2 px-4 rounded-lg text-center"
                                >
                                    <a
                                        href={URL.createObjectURL(url)}
                                        download={`${String(name + lastname)
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                        className="text-white font-body"

                                    >
                                        GUARDAR CONTACTO
                                    </a>
                                </motion.button>
                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="text-xl text-center" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="text-white">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                        </div>
                    </>
                </div>
            </section>

            <p className="bg-procaps text-white border-solid border-procaps w-full p-5 rounded-lg text-xl inline-flex items-center font-medium mt-10  text-center justify-center">
                <svg width={80} height={50} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1.5c-4.14 0-7.5 3.024-7.5 6.75 0 6 7.5 14.25 7.5 14.25s7.5-8.25 7.5-14.25c0-3.726-3.36-6.75-7.5-6.75ZM12 12a3 3 0 1 1 0-5.999A3 3 0 0 1 12 12Z" />
                </svg>
                Antiguo Cuscatlán, La Libertad, El Salvador
            </p>

            <section className="mt-5">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                    className="slider-procaps  custom-container"
                >
                    <Slider {...sliderSettings}>
                        <div>
                            <a
                                className={socialStyle} href={"mailto:" + email}>
                                <img src={email_} className={imageStyle} alt="Email" />
                            </a>
                        </div>
                        <div>
                            <a className={socialStyle} href={"tel:" + phone}>
                                <img src={phoneImg} className={imageStyle} alt="Teléfono" />
                            </a>
                        </div>
                        <div>
                            <a
                                className={socialStyle + ` ${animated?.whats_animated && 'animate-bounce'} `} href={"https://wa.me/" + phone}>
                                <img src={whatsapp} className={imageStyle} alt="Whatsapp" />
                            </a>
                        </div>
                        {linkedin &&
                            <div>
                                <a className={socialStyle} href={linkedin.includes("https://www.linkedin.com/in") ? linkedin : "https://www.linkedin.com/in/" + linkedin}>
                                    <img src={linkedinImg} className={imageStyle} alt="Linkedin" />
                                </a>
                            </div>}
                        {microsoftteams &&
                            <div>
                                <a className={socialStyle}
                                    href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams}
                                    target="_blank"
                                    rel="noreferrer">
                                    <img src={microsoftteamsp} className={imageStyle} alt="Microsoft Teams" />
                                </a>
                            </div>}
                        {facebook &&
                            <div>
                                <a className={socialStyle} href={
                                    typeOfDevice === "Android" ? (facebook.includes("https://www.facebook.com/") ? `fb://facewebmodal/f?href=${facebook}` : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`) : (
                                        typeOfDevice === "iOS" ? (facebook.includes("https://www.facebook.com/") ? `fb://profile/${facebook.replace("https://www.facebook.com/", "")}` : `fb://profile/${facebook}`) : (facebook.includes("https://www.facebook.com/") ? `${facebook}` : `https://www.facebook.com/${facebook}/`)
                                    )
                                } >
                                    <img src={facebookp} className={imageStyle} alt="Facebook" />
                                </a>
                            </div>}
                        {instagram &&
                            <div>
                                <a className={socialStyle} href={instagram.includes("https://www.instagram.com/") ? `${instagram}` : `https://instagram.com/${instagram}`} >
                                    <img src={instagramp} className={imageStyle} alt="Instagram" />
                                </a>
                            </div>}
                        {twitter &&
                            <div>
                                <a className={socialStyle} href={twitter.includes("https://twitter.com/") ? `${twitter}` : `https://twitter.com/${twitter}`}>
                                    <img src={twitterp} className={imageStyle} alt="Twitter" />
                                </a>
                            </div>}
                        {video &&
                            <div>
                                <a className={socialStyle} href={`${video}`} target="_blank" rel="noreferrer">
                                    <img src={videop} className={imageStyle} alt="VIDEO" />
                                </a>
                            </div>}
                    </Slider>
                </motion.div>
            </section>

            <section className="flex justify-center items-center mt-5">
                <a href="https://www.procapslaboratorios.com/vademecum" target="_blank" rel="noopener noreferrer">
                    <motion.img
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1.9 }}
                        className="mx-auto drop-shadow-lg" src={vadecum} alt="carnot" />
                </a>
            </section>

        </main >
    )
}

export default MainCointainer