import React, { useContext } from "react";
import { motion } from "framer-motion";
import { FirebaseFuntions } from "../../context/FirebaseContext";
import { Link } from "react-router-dom";
import { Input } from "antd";

const ForgotPassword = () => {

    const { userForgotPassword, loginStatus, setLoginStatus } = useContext(FirebaseFuntions);


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = Array.from(new FormData(e.target));
        const obj = Object.fromEntries(data);
        const resultado = userForgotPassword(obj);
        resultado.then((res) => {
            setLoginStatus(res);
        });
    };


    return (
        <div className="md:w-4/12 sm:w-5/6 top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">

            <motion.div
                className="bg-black shadow-xl p-7 rounded-lg"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    default: {
                        duration: 0.4,
                        ease: [0, 0.71, 0.2, 1.01]
                    },
                    scale: {
                        type: "spring",
                        damping: 8,
                        stiffness: 100,
                        restDelta: 0.001
                    }
                }}
            >
                <h1 className="text-white text-2xl mb-10 font-bold uppercase" >¿Olvidaste tu contraseña?</h1>
                <p className="text-white mb-5 text-sm font-normal">{loginStatus}</p>
                <form className="flex flex-col m-3" onSubmit={handleSubmit}>
                    {/* <input className="mb-5 rounded-lg p-2 focus:outline-none" id="mail" name="mail" type="text" placeholder="Mail" required /> */}
                    <Input className="mb-5 rounded-lg p-2 focus:outline-none" id="mail" name="mail" type="text" placeholder="Mail" required />
                    <motion.button
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        className="bg-makinn_primary text-black font-bold py-2 px-4 rounded-lg uppercase focus:outline-none"
                        type="submit">
                        Recuperar contraseña
                    </motion.button>
                </form>
                <p className="text-white text-sm font-normal mb-5"> ¿Ya tienes cuenta? <Link className="text-slate-100 underline" to="/" >Inicia Sesión</Link></p>
            </motion.div>
        </div>
    );
};
export default ForgotPassword;