import React, { useContext, useState, useEffect } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import logo from "../../assets/makinn/makinn.png"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import inputJson from "./inputfields"
import complete from "../../assets/img/complete.svg"
import { Input, Checkbox } from "antd"

const fieldStyle = {
    input: "m-3 w-full p-3"
}

const CreateAccount = () => {
    const { createUser, userExists } = useContext(FirebaseFuntions)
    const [createStatus, setCreateStatus] = useState("")
    const [passwordFlag, setPasswordFlag] = useState("")
    const [loader, setLoader] = useState(false)
    const { userId, projectId } = useParams()
    const [userExist, setUserExist] = useState(true)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = Array.from(new FormData(e.target))
        const obj = Object.fromEntries(data);
        if (obj.terms) {
            if (obj.password === obj.password2) {
                const status = createUser({ ...obj, userId: userId, projectId: projectId });
                status.then((res) => {
                    setCreateStatus(res);
                    if (res === "Usuario creado correctamente") {
                        setTimeout(() => {
                            navigate(`/${projectId}/${userId}`);
                        }, 2000);
                    }
                });
            } else {
                setPasswordFlag("Las contraseñas no coinciden");
            }
        } else {
            setPasswordFlag("Debes aceptar los términos y condiciones");
        }
    }

    const handleLoader = () => {
        //console.log("false")
        setTimeout(() => {
            setLoader(true)
            //console.log("true")
        }, 2000)
    }

    useEffect(() => {
        handleLoader()
        const status = userExists(userId, projectId)
        status.then((res) => {
            if (res) {
                navigate(`/${projectId}/${userId}`)
            }
            setUserExist(res)
        })

    }, [userId, projectId, navigate, userExists])

    return (
        <div className="flex w-full items-center justify-center">
            <div className="md:w-6/12 sm:w-full sm:p-4 text-center">
                {
                    userExist ? (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    ) : (
                        !loader ? (
                            <AnimatePresence>
                                <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                    <motion.img
                                        initial={{ rotateY: 0 }}
                                        animate={{ rotateY: 360 }}
                                        transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                        exit={{ opacity: 0 }}
                                        src={logo}
                                        alt="logo makinn"
                                        className="mx-auto mb-5"
                                        width={150} height={150}
                                    />
                                    <p className="uppercase text-lg">Cargando</p>
                                </div>
                            </AnimatePresence>
                        ) : (
                            <motion.div
                                className="bg-black shadow-xl rounded-xl flex md:flex-row sm:flex-col"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    default: {
                                        duration: 0.4,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    },
                                    scale: {
                                        type: "spring",
                                        damping: 8,
                                        stiffness: 100,
                                        restDelta: 0.001
                                    }
                                }}
                            >
                                <div className="md:w-3/5 sm:w-full p-7" >
                                    <h1 className="text-white text-left text-xl mb-5 font-bold font-heading uppercase" >Crear cuenta</h1>
                                    <div className="p-4 border-2 border-white rounded-lg">
                                        <p className="text-white font-body">
                                            Por favor, completa el formato de datos básicos para crear tu cuenta. Una vez creada, podrás personalizar la información accediendo a las opciones de cuenta.
                                        </p>
                                    </div>
                                    <p className="text-white text-left text-sm mb-5" >{createStatus}</p>
                                    <p className="text-white text-left text-sm mb-5" >{passwordFlag}</p>
                                    <form className="flex flex-col" onSubmit={handleSubmit}>
                                        <div className="flex flex-row flex-wrap justify-between">
                                            {
                                                inputJson.map((field, key) => {
                                                    return (
                                                        // <input key={key} className={fieldStyle.input} id={field.id} name={field.name} type={field.type} placeholder={field.placeholder} required={field.required} autoComplete="off" />
                                                        <Input key={key} className={fieldStyle.input} id={field.id} name={field.name} type={field.type} placeholder={field.placeholder} required={field.required} autoComplete="off" />
                                                    )
                                                })
                                            }
                                            <label className="w-full flex items-center justify-start p-3">
                                                {/* <input type="checkbox" id="terms" name="terms" value={true} /> */}
                                                <Checkbox id="terms" name="terms" value={true}  className="mr-2"/>
                                                <span className="text-white text-left text-sm font-body" >Acepto he leído el <Link className="underline" to={`/policy-advice/${projectId}/${userId}`}>aviso de privacidad</Link></span>
                                            </label>
                                        </div>
                                        <motion.button
                                            whileHover={{ scale: 1.03 }}
                                            whileTap={{ scale: 0.9 }}
                                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                            className="bg-makinn_primary text-black font-bold m-3 py-2 px-4 rounded-xl uppercase focus:outline-none font-body"
                                            type="submit"
                                        >
                                            Crear cuenta
                                        </motion.button>
                                    </form>
                                </div>
                                <div className="md:w-2/5 sm:w-full bg-makinn_primary rounded-r-lg h-auto p-4 md:flex flex-col items-center justify-center sm:hidden">
                                    <img src={complete} alt="complete" className="w-4/5 mx-auto mb-5" />
                                    <h2 className="text-white text-left text-xl mb-2 font-bold font-heading uppercase w-full" >¿Qué sigue?</h2>
                                    <p className="text-white text-left text-md mb-5 font-body">Una vez hayas completado tu perfil podrás modificar los siguientes datos:</p>
                                    <ul className="text-white text-left text-md list-inside list-disc leading-8 font-body">
                                        <li>Nombre.</li>
                                        <li>Apellido.</li>
                                        <li>Correo electrónico.</li>
                                        <li>Foto de perfil.</li>
                                        <li>Logo.</li>
                                        <li>Apariencia de la bcard.</li>
                                        <li>Redes sociales.</li>
                                        <li>Puesto de trabajo.</li>
                                    </ul>
                                </div>
                            </motion.div>
                        )
                    )
                }
            </div>
        </div>
    );
};

export default CreateAccount;
