import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import wen from "../../../assets/templates/wen/logo_wen.png";
import profile from "../../../assets/social_icons/profile.png"
import linkedinImg from "../../../assets/social_icons/linkedin.png";
import email_ from "../../../assets/social_icons/email.png";
import phoneImg from "../../../assets/social_icons/phone.png";
import whatsapp from "../../../assets/social_icons/whatsapp.png";
import facebookp from "../../../assets/social_icons/facebook.png";
import instagramp from "../../../assets/social_icons/instagram.png";
import twitterp from "../../../assets/social_icons/xlogo.png";
import videop from "../../../assets/social_icons/video.png";
import microsoftteamslg from "../../../assets/social_icons/microsoftteams.png"
import generateVcard from "../../../functions/generateVcard";
import QrModal from "../../../components/QrModal/QrModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Configuración del carrusel
const sliderSettings = {
    dots: false, // Muestra los puntos de navegación
    infinite: false, // Permite un desplazamiento infinito
    speed: 500, // Velocidad de transición en milisegundos
    slidesToShow: 3, // Número de elementos mostrados a la vez
    slidesToScroll: 1, // Número de elementos desplazados en cada cambio
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2, // Cambia el número de elementos mostrados en dispositivos más pequeños
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2, // Cambia el número de elementos mostrados en dispositivos aún más pequeños
            },
        },
    ],
};

const socialStyle = "flex flex-col drop-shadow-lg items-center justify-center";
const labelStyle = "text-center sm:text-lg text-white font-bold font-body uppercase";
const imageStyle = "xl:w-[110px] xl:h-[110px] sm:w-12 sm:h-12 p-2";
const cardStyle = "bg-wen_primary_100 rounded-lg py-2";

const MainCointainer = ({ user, typeOfDevice }) => {
    const {
        name,
        lastname,
        email,
        phone,
        linkedin,
        photo,
        facebook,
        instagram,
        twitter,
        web,
        job,
        video,
        animated,
        microsoftteams
    } = user;

    const [showQr, setShowQr] = useState(false);

    const url = useMemo(() => {
        const data = generateVcard(user);
        return data;
    }, [user]);

    const handleQR = () => {
        setShowQr(!showQr);
    };

    return (
        <main className="flex flex-col md:p-20 sm:p-5 bg-black xl:h-full lg:h-full md:h-screen xs:h-screen">
            <section className="flex flex-col justify-center items-center">
                <motion.img
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className="mx-auto drop-shadow-lg"
                    src={wen}
                    alt="wen"
                />
            </section>
            <section className="flex md:justify-center md:flex-col sm:flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                {/* INFORMACION PERSONAL */}
                <div className="relative w-full ">
                    <div
                        className="absolute bg-gradient-to-t from-wen_primary_100 to-wen_primary_20 w-full rounded-lg shadow-lg top-1/4" style={{ height: "78%" }}>

                    </div>
                    <>
                        <motion.div className="flex flex-col items-center justify-center">
                            <img
                                className="md:w-56 md:mt-0 sm:mt-5 sm:w-40 rounded-full mx-auto drop-shadow-lg border-4 border-wen_primary_100"
                                src={photo ? photo : profile}
                                alt="profile"
                            />
                            {/* <QR></QR> */}
                            <motion.button
                                onClick={handleQR}
                                title="Ver QR"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ filter: "none" }}
                                className="relative flex flex-col items-center justify-center bg-gradient-to-r from-cyan-700 to-cyan-600 rounded-full p-2 -top-5">
                                <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    stroke="#ffff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path
                                        fill="#ffff"
                                        stroke="none"
                                        d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                                    ></path>
                                    <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                                </svg>
                            </motion.button>
                        </motion.div>
                        {/* DATOS */}
                        <div className="leading-tight mb-4 flex flex-col justify-center items-center">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center text-white font-heading"
                            >
                                {name} {lastname}
                            </motion.h1>
                            {job && (
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center text-white uppercase font-body"
                                >
                                    {job}
                                </motion.h2>
                            )}
                            {web && (
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="md:text-xl sm:text-sm"
                                    href={web.includes("https://") ? `${web}` : `https://${web}/`}
                                >
                                    <span className="ml-2 text-white font-body">
                                        {web.includes("https://")
                                            ? web.replace("https://", "")
                                            : web}
                                    </span>
                                </motion.a>
                            )}
                            {
                                <motion.button
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="bg-cyan-600 hover:bg-wen_primary_100 text-white font-bold py-2 px-4 rounded-lg mt-5"
                                >
                                    <a
                                        href={URL.createObjectURL(url)}
                                        download={`${String(name + lastname)
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                        className="text-white"
                                    >
                                        GUARDAR CONTACTO
                                    </a>
                                </motion.button>
                            }
                        </div>
                    </>
                </div>
            </section>

            <section className="md:my-10 xs:my-12">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                >
                    <Slider className="sm:w-4/5 md:w-full mx-auto track-config slide-config" {...sliderSettings}>
                        <div className={cardStyle}>
                            <p className={labelStyle}>Teléfono</p>
                            <a className={socialStyle} href={"tel:" + phone}>
                                <img src={phoneImg} className={imageStyle} alt="Teléfono" />
                            </a>
                        </div>
                        <div className={cardStyle}>
                            <p className={labelStyle}>WhatsApp</p>
                            <a
                                className={
                                    socialStyle +
                                    ` ${animated?.whats_animated && "animate-bounce"} `
                                }
                                href={"https://wa.me/" + phone}
                            >
                                <img src={whatsapp} className={imageStyle} alt="Whatsapp" />
                            </a>
                        </div>
                        <div className={cardStyle}>
                            <p className={labelStyle}>Email</p>
                            <a className={socialStyle} href={"mailto:" + email}>
                                <img src={email_} className={imageStyle} alt="Email" />
                            </a>
                        </div>
                        {linkedin && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>LinkedIn</p>
                                <a
                                    className={socialStyle}
                                    href={
                                        linkedin.includes("https://www.linkedin.com/in")
                                            ? linkedin
                                            : "https://www.linkedin.com/in/" + linkedin
                                    }
                                >
                                    <img
                                        src={linkedinImg}
                                        className={imageStyle}
                                        alt="Linkedin"
                                    />
                                </a>
                            </div>
                        )}
                        {facebook && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>Facebook</p>
                                <a
                                    className={socialStyle}
                                    href={
                                        typeOfDevice === "Android"
                                            ? facebook.includes("https://www.facebook.com/")
                                                ? `fb://facewebmodal/f?href=${facebook}`
                                                : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`
                                            : typeOfDevice === "iOS"
                                                ? facebook.includes("https://www.facebook.com/")
                                                    ? `fb://profile/${facebook.replace(
                                                        "https://www.facebook.com/",
                                                        ""
                                                    )}`
                                                    : `fb://profile/${facebook}`
                                                : facebook.includes("https://www.facebook.com/")
                                                    ? `${facebook}`
                                                    : `https://www.facebook.com/${facebook}/`
                                    }
                                >
                                    <img
                                        src={facebookp}
                                        className={imageStyle}
                                        alt="Facebook"
                                    />
                                </a>
                            </div>
                        )}
                        {instagram && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>Instagram</p>
                                <a
                                    className={socialStyle}
                                    href={
                                        instagram.includes("https://www.instagram.com/")
                                            ? `${instagram}`
                                            : `https://instagram.com/${instagram}`
                                    }
                                >
                                    <img
                                        src={instagramp}
                                        className={imageStyle}
                                        alt="Instagram"
                                    />
                                </a>
                            </div>
                        )}
                        {twitter && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>X (Twitter)</p>
                                <a
                                    className={socialStyle}
                                    href={
                                        twitter.includes("https://twitter.com/")
                                            ? `${twitter}`
                                            : `https://twitter.com/${twitter}`
                                    }
                                >
                                    <img src={twitterp} className={imageStyle} alt="Twitter" />
                                </a>
                            </div>
                        )}
                        {video && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>VIDEO</p>
                                <a
                                    className={socialStyle}
                                    href={`${video}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={videop} className={imageStyle} alt="VIDEO" />
                                </a>
                            </div>
                        )}
                        {microsoftteams && (
                            <div className={cardStyle}>
                                <p className={labelStyle}>MS Teams</p>
                                <a
                                    className={socialStyle}
                                    href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={microsoftteamslg} className={imageStyle} alt="Microsoft Teams" />

                                </a>
                            </div>
                        )}
                    </Slider>
                </motion.div>
            </section>
        </main>
    );
};

export default MainCointainer;
