import React, { useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import { useNavigate, useParams, Link } from "react-router-dom"
import logomakinn from "../../assets/makinn/makinn.png"
import { Select, Input } from "antd"

const Home = () => {

    const { userLogin, loginStatus, setLoginStatus, loginFlag, idDoc, readAllProjects, userProject } = useContext(FirebaseFuntions)
    const goDashboard = useNavigate()
    const { projectId } = useParams()
    const [company, setCompany] = useState([])
    const [selectCompany, setSelectCompany] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = Array.from(new FormData(e.target))
        const obj = Object.fromEntries(data)
        if (projectId !== undefined) {
            obj.company = projectId
        }
        if (selectCompany !== undefined && selectCompany !== null && selectCompany.length > 0) {
            obj.company = selectCompany
        }
        const resultado = userLogin(obj)
        resultado.then((res) => {
            setLoginStatus(res)
        });
    };

    useEffect(() => {
        if (loginFlag) {
            if (idDoc === undefined || idDoc === null || idDoc.length === 0) {
                goDashboard(`/`)
            } else {
                goDashboard(`/${userProject}/${idDoc}`)
                //console.log("idDoc", idDoc)
            }
        }
        const response = readAllProjects()
        response.then((res) => {
            setCompany(res)
        })
        response.catch((err) => {
            console.log(err)
            setCompany(["promo"])
        })
    }, [loginFlag, idDoc, userProject, goDashboard, readAllProjects])

    return (
        <div className="md:w-4/12 sm:w-5/6 top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
            <motion.img
                src={logomakinn}
                alt="logo promo"
                className="w-20 mx-auto mb-5"
                initial={{ rotateY: 270 }}
                animate={{ rotateY: 0 }}
                transition={{
                    default: {
                        duration: 1.5
                    }
                }}
            />
            <motion.div
                className="bg-black shadow-xl p-7 rounded-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    default: {
                        delay: 0.8,
                        duration: 1.5
                    }
                }}
            >
                <h1 className="text-3xl text-white mb-5 font-bold uppercase font-heading" >Iniciar sesión</h1>
                <p className="text-white mb-5 text-sm font-normal">{loginStatus}</p>
                <form className="flex flex-col m-3" onSubmit={handleSubmit}>
                    {
                        (projectId === undefined || projectId === null || projectId.length === 0) ?
                            <>
                                <label className="text-white text-sm font-normal mb-5 text-left uppercase font-body" htmlFor="company">Proyecto</label>
                                <Select
                                    id="company"
                                    name="company"
                                    required
                                    placeholder="Selecciona un proyecto"
                                    allowClear
                                    showSearch={true}
                                    className="uppercase mx-3 flex"
                                    onChange={(value) => setSelectCompany(value)}
                                >
                                    {company.map((item, index) => (
                                        <Select.Option key={index} value={item} className="uppercase">
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </>
                            :
                            <p className="text-white text-sm font-normal text-left uppercase font-body">
                                Proyecto: {projectId}
                            </p>

                    }
                    <label className={`text-white text-sm font-normal mb-2 ${projectId ? 'mt-2' : 'mt-5'} text-left uppercase font-body`}>Datos de usuario</label>
                    <div className="border-2 border-white flex flex-col p-4 rounded-lg">
                        {/* <input className="mb-5 rounded-lg p-2 focus:outline-none font-body" id="mail" name="mail" type="text" placeholder="Mail" required /> */}
                        <Input
                            className="mb-5 p-2"
                            id="mail"
                            name="mail"
                            type="mail"
                            placeholder="Mail"
                            autoComplete="true"
                            required
                            classNames="font-body"
                        />

                        {/* <input className="mb-5 rounded-lg p-2 focus:outline-none font-body" id="password" name="password" type="password" placeholder="Contraseña" autoComplete="off" required /> */}

                        <Input
                            className="mb-5 p-2"
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Contraseña"
                            autoComplete="off"
                            required
                        />

                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            className="bg-makinn_primary text-white font-bold py-2 px-4 rounded-lg uppercase focus:outline-none font-body"
                            type="submit">
                            Ingresar
                        </motion.button>
                    </div>
                </form>
                <Link className="text-white text-sm font-normal hover:underline font-body" to="/forgot-password" >¿Olvidaste tu constraseña?</Link>
            </motion.div>
        </div>
    );
};
export default Home;